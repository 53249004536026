import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Menu, Transition } from "@headlessui/react";

import { usePopper } from "./hooks";

/*class ComponentMenuPortal extends React.Component {
    constructor(props) {
        super(props);
        this.el = document.createElement("div");
    }

    componentDidMount() {
        // The portal element is inserted in the DOM tree after
        // the Modal's children are mounted, meaning that children
        // will be mounted on a detached DOM node. If a child
        // component requires to be attached to the DOM tree
        // immediately when mounted, for example to measure a
        // DOM node, or uses 'autoFocus' in a descendant, add
        // state to Modal and only render the children when Modal
        // is inserted in the DOM tree.
        const modalRoot = document.getElementById("body-for-popper");
        modalRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        const modalRoot = document.getElementById("body-for-popper");
        modalRoot.removeChild(this.el);
    }

    render() {
        return ReactDOM.createPortal(this.props.children, this.el);
    }
}*/

const MenuPortal = React.forwardRef(({ children, style }, ref) => {
    if (typeof document === "undefined") return <div></div>;

    const el = useRef(document.createElement("div"));

    useEffect(() => {
        const modalRoot = document.getElementById("body-for-popper");
        modalRoot.appendChild(el.current);
        const elCurrent = el.current;
        return () => {
            modalRoot.removeChild(elCurrent);
        };
    }, []);

    return ReactDOM.createPortal(
        <div ref={ref} style={style}>
            {children}
        </div>,
        el.current
    );
});

const PuiMenu = React.forwardRef(({ children, placement = "bottom-start" }, ref) => {
    let [trigger, container] = usePopper({
        placement,
        strategy: "fixed",
        modifiers: [{ name: "offset", options: { offset: [0, 10] } }],
    });

    const childrenArray = React.Children.toArray(children);

    //console.log("childrenArray", childrenArray);

    return (
        <Menu as="div">
            {({ open }) => (
                <>
                    <span ref={ref}>
                        <Menu.Button ref={trigger}>
                            {childrenArray.filter(
                                (child) => child.type.displayName === "MenuButton"
                            )}
                            {/*childrenArray[0]*/}
                        </Menu.Button>
                    </span>

                    <MenuPortal
                        ref={container}
                        className="border-none"
                        style={{ zIndex: 1000 }}
                    >
                        <Transition
                            show={open}
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >
                            <Menu.Items static>
                                {childrenArray.filter(
                                    (child) => child.type.displayName === "MenuItems"
                                )}
                                {/*childrenArray[1]*/}
                            </Menu.Items>
                        </Transition>
                    </MenuPortal>
                </>
            )}
        </Menu>
    );
});

export const MenuButton = ({ children, className }) => {
    return <div className={className}>{children}</div>;
};

export const MenuItems = ({ children, className = "w-64" }) => {
    console.log("MenuItems", children);

    return (
        <div
            className={`divide-y divide-gray-100 dark:divide-sgray-800
                        rounded-lg border border-gray-200 dark:border-sgray-700
                        bg-sgray-50 dark:bg-sgray-900
                        shadow-xl outline-none ${className}`}
        >
            {children.map((child, index) => (
                <Menu.Item as="div" key={index} className="block w-full text-left">
                    {({ active }) => (
                        <div
                            className={` ${
                                active ? "bg-sgray-200 dark:bg-sgray-800" : ""
                            } `}
                        >
                            {child}
                        </div>
                    )}
                </Menu.Item>
            ))}
        </div>
    );
};

export const MenuItem = ({
    children,
    as = "button",
    onClick,
    Icon,
    title,
    divideTop = false,
    divideBottom = false,
    subMenuPlacement = "left",
    iconClassName = "",
    className = "",
}) => {
    const [openSubMenu, setOpenSubMenu] = useState(false);

    let cn;
    if (className) {
        cn = className;
    } else {
        cn = "relative px-4 py-2.5 w-full flex items-center space-x-4 text-sm";

        if (divideTop) {
            cn += " border-t border-gray-300 dark:border-sgray-600";
        }

        if (divideBottom) {
            cn += " border-b border-gray-300 dark:border-sgray-600";
        }

        //cn += " " + className;
    }

    const childrenArray = React.Children.toArray(children);

    const handleSubMenu = (e) => {
        e.stopPropagation();

        setOpenSubMenu((x) => !x);

        if (onClick) {
            onClick();
        }
    };

    if (as === "submenu") {
        return (
            <button className={cn} onClick={handleSubMenu}>
                {Icon && <Icon className={"w-6 h-6 " + iconClassName} />}
                {title && <span className="text-sm">{title}</span>}
                {openSubMenu && childrenArray
                    ? childrenArray.filter(
                          (child) => child.type && child.type.displayName === "SubMenuItems"
                      )
                    : null}
            </button>
        );
    } else if (as === "button") {
        return (
            <button className={cn} onClick={onClick}>
                {Icon && <Icon className={"w-6 h-6 " + iconClassName} />}
                {title && <span className="text-sm">{title}</span>}
                {children}
            </button>
        );
    } else {
        return (
            <div className={cn} onClick={onClick}>
                {Icon && <Icon className={"w-6 h-6 " + iconClassName} />}
                {title && <span className="text-sm">{title}</span>}
                {children}
            </div>
        );
    }
};

export const SubMenuItems = ({
    children,
    className = "absolute w-64 -left-[272px] top-0",
}) => {
    return (
        <div
            onClick={(e) => e.stopPropagation()}
            className={`divide-y divide-gray-100 dark:divide-sgray-800
                        rounded-lg border border-gray-200 dark:border-sgray-700
                        bg-sgray-100 dark:bg-sgray-900
                        shadow-xl outline-none cursor-default ${className}`}
        >
            {children}
        </div>
    );
};

// add custom displayName property
MenuButton.displayName = "MenuButton";
MenuItems.displayName = "MenuItems";
MenuItem.displayName = "MenuItem";
SubMenuItems.displayName = "SubMenuItems";

PuiMenu.Button = MenuButton;
PuiMenu.Items = MenuItems;
PuiMenu.Item = MenuItem;
PuiMenu.SubMenuItems = SubMenuItems;

export default PuiMenu;
