import React from "react";
import styles from "./PurpleDoorLogoHorizontal.module.css";

const PurpleDoorLogoHorizontal = ({ inverse = false }) => {
    let st0 = styles.st0;
    let st1 = styles.st1;
    let st2 = styles.st2;

    if (inverse) {
        st0 = styles.st0_inverse;
        st1 = styles.st1_inverse;
        st2 = styles.st2_inverse;
    }

    return (
        <svg
            height="48"
            viewBox="0 0 1195.12 146.03"
            style={{
                enableBackground: "new 0 0 1195.12 146.03",
            }}
        >
            <g>
                <g>
                    <g>
                        <path
                            className={st0}
                            d="M145.57,38.99c5.14,0,9.48,0.93,13.04,2.78c3.56,1.86,6.27,4.47,8.13,7.84c1.86,3.37,2.79,7.37,2.79,11.99
				c0,2.74-0.38,5.49-1.14,8.25c-0.76,2.77-1.98,5.28-3.64,7.52c-1.67,2.25-3.9,4.06-6.69,5.43c-2.79,1.37-6.22,2.05-10.29,2.05
				h-8.81v21.25h-12.28V38.99H145.57z M147.69,72c1.86,0,3.42-0.35,4.66-1.05c1.24-0.7,2.21-1.58,2.92-2.65
				c0.71-1.06,1.21-2.17,1.52-3.33c0.31-1.15,0.47-2.16,0.47-3.01c0-0.67-0.1-1.56-0.3-2.69c-0.2-1.12-0.61-2.26-1.23-3.42
				c-0.62-1.15-1.57-2.13-2.84-2.92c-1.27-0.79-2.98-1.19-5.12-1.19h-8.81V72H147.69z"
                        />
                        <path
                            className={st0}
                            d="M191.55,81.3c0,2.13,0.59,4.17,1.78,6.11c1.19,1.95,2.75,3.53,4.7,4.74c1.95,1.22,4.08,1.82,6.39,1.82
				c2.54,0,4.8-0.61,6.77-1.82c1.98-1.22,3.54-2.8,4.7-4.74c1.16-1.94,1.74-3.98,1.74-6.11V38.99h12.11v42.59
				c0,4.92-1.13,9.29-3.39,13.09c-2.26,3.8-5.31,6.78-9.14,8.94c-3.84,2.16-8.1,3.24-12.79,3.24c-4.63,0-8.85-1.08-12.66-3.24
				c-3.81-2.16-6.85-5.14-9.1-8.94c-2.26-3.8-3.39-8.16-3.39-13.09V38.99h12.28V81.3z"
                        />
                        <path
                            className={st0}
                            d="M263.43,38.99c3.9,0,7.37,0.53,10.42,1.6c3.05,1.06,5.6,2.58,7.66,4.56c2.06,1.98,3.63,4.35,4.7,7.11
				c1.07,2.77,1.61,5.88,1.61,9.35c0,2.74-0.38,5.47-1.14,8.21c-0.76,2.74-2,5.23-3.73,7.48c-1.72,2.25-3.99,4.06-6.82,5.43
				c-2.82,1.37-6.32,2.05-10.5,2.05h-8.81v21.34h-12.28V38.99H263.43z M265.55,72c1.86,0,3.42-0.33,4.66-1
				c1.24-0.67,2.21-1.52,2.92-2.55c0.71-1.03,1.21-2.11,1.52-3.24c0.31-1.12,0.47-2.17,0.47-3.15c0-0.73-0.1-1.66-0.3-2.78
				c-0.2-1.12-0.61-2.26-1.23-3.42c-0.62-1.15-1.57-2.13-2.84-2.92c-1.27-0.79-2.98-1.19-5.12-1.19h-8.81V72H265.55z M277.07,80.21
				l15.41,25.9h-14.31l-15.75-25.54L277.07,80.21z"
                        />
                        <path
                            className={st0}
                            d="M321.52,38.99c5.14,0,9.48,0.93,13.04,2.78c3.56,1.86,6.27,4.47,8.13,7.84c1.86,3.37,2.79,7.37,2.79,11.99
				c0,2.74-0.38,5.49-1.14,8.25c-0.76,2.77-1.98,5.28-3.64,7.52c-1.67,2.25-3.9,4.06-6.69,5.43c-2.79,1.37-6.22,2.05-10.29,2.05
				h-8.81v21.25h-12.28V38.99H321.52z M323.64,72c1.86,0,3.42-0.35,4.66-1.05c1.24-0.7,2.21-1.58,2.92-2.65
				c0.71-1.06,1.21-2.17,1.52-3.33c0.31-1.15,0.47-2.16,0.47-3.01c0-0.67-0.1-1.56-0.3-2.69c-0.2-1.12-0.61-2.26-1.23-3.42
				c-0.62-1.15-1.57-2.13-2.84-2.92c-1.27-0.79-2.98-1.19-5.12-1.19h-8.81V72H323.64z"
                        />
                        <path
                            className={st0}
                            d="M356.49,38.99h12.28v54.35h29.3v12.77h-41.58V38.99z"
                        />
                        <path
                            className={st0}
                            d="M410.76,38.99h42.51v12.77h-30.23v14.32h26.76v12.77h-26.76v14.5h31.41v12.77h-43.69V38.99z"
                        />
                        <path
                            className={st0}
                            d="M494.67,106.11V38.99h18.63c6.27,0,11.6,1,16,3.01c4.4,2.01,7.94,4.68,10.63,8.03
				c2.68,3.34,4.64,7.08,5.88,11.22c1.24,4.14,1.86,8.3,1.86,12.49c0,4.99-0.83,9.47-2.5,13.45c-1.67,3.98-3.94,7.39-6.82,10.21
				s-6.21,4.99-9.99,6.48c-3.78,1.49-7.79,2.23-12.02,2.23H494.67z M506.95,93.34h7.28c3.1,0,5.93-0.44,8.47-1.32
				c2.54-0.88,4.73-2.17,6.56-3.88c1.83-1.7,3.25-3.84,4.23-6.43c0.99-2.58,1.48-5.55,1.48-8.89c0-4.38-0.66-7.95-1.99-10.72
				c-1.33-2.77-2.96-4.91-4.91-6.43c-1.95-1.52-3.94-2.57-5.97-3.15c-2.03-0.58-3.75-0.87-5.17-0.87h-9.99V93.34z"
                        />
                        <path
                            className={st0}
                            d="M556.15,72.64c0-4.62,0.82-9,2.46-13.13c1.64-4.13,3.91-7.8,6.82-10.99c2.91-3.19,6.28-5.7,10.12-7.52
				c3.84-1.82,7.96-2.74,12.36-2.74c4.35,0,8.44,0.91,12.28,2.74c3.84,1.82,7.24,4.33,10.2,7.52c2.96,3.19,5.28,6.86,6.94,10.99
				c1.66,4.14,2.5,8.51,2.5,13.13c0,4.74-0.83,9.18-2.5,13.31c-1.67,4.14-3.98,7.77-6.94,10.9c-2.96,3.13-6.37,5.58-10.2,7.34
				c-3.84,1.76-7.93,2.64-12.28,2.64c-4.4,0-8.52-0.88-12.36-2.64c-3.84-1.76-7.21-4.21-10.12-7.34c-2.91-3.13-5.18-6.76-6.82-10.9
				C556.97,81.82,556.15,77.38,556.15,72.64z M568.85,72.64c0,2.98,0.49,5.76,1.48,8.34c0.99,2.58,2.37,4.86,4.15,6.84
				c1.78,1.98,3.84,3.51,6.18,4.61c2.34,1.09,4.9,1.64,7.66,1.64c2.65,0,5.12-0.55,7.41-1.64s4.28-2.63,5.97-4.61
				c1.69-1.98,3.02-4.25,3.98-6.84c0.96-2.58,1.44-5.37,1.44-8.34c0-3.04-0.5-5.87-1.48-8.48c-0.99-2.61-2.34-4.91-4.06-6.89
				c-1.72-1.98-3.74-3.51-6.05-4.61c-2.31-1.09-4.83-1.64-7.54-1.64c-2.71,0-5.22,0.55-7.54,1.64c-2.32,1.09-4.35,2.63-6.1,4.61
				c-1.75,1.98-3.1,4.27-4.06,6.89C569.33,66.77,568.85,69.6,568.85,72.64z"
                        />
                        <path
                            className={st0}
                            d="M628.29,72.64c0-4.62,0.82-9,2.46-13.13c1.64-4.13,3.91-7.8,6.82-10.99c2.91-3.19,6.28-5.7,10.12-7.52
				c3.84-1.82,7.96-2.74,12.36-2.74c4.35,0,8.44,0.91,12.28,2.74c3.84,1.82,7.24,4.33,10.2,7.52c2.96,3.19,5.28,6.86,6.94,10.99
				c1.66,4.14,2.5,8.51,2.5,13.13c0,4.74-0.83,9.18-2.5,13.31c-1.67,4.14-3.98,7.77-6.94,10.9c-2.96,3.13-6.37,5.58-10.2,7.34
				c-3.84,1.76-7.93,2.64-12.28,2.64c-4.4,0-8.52-0.88-12.36-2.64c-3.84-1.76-7.21-4.21-10.12-7.34c-2.91-3.13-5.18-6.76-6.82-10.9
				C629.11,81.82,628.29,77.38,628.29,72.64z M640.99,72.64c0,2.98,0.49,5.76,1.48,8.34c0.99,2.58,2.37,4.86,4.15,6.84
				c1.78,1.98,3.84,3.51,6.18,4.61c2.34,1.09,4.9,1.64,7.66,1.64c2.65,0,5.12-0.55,7.41-1.64s4.28-2.63,5.97-4.61
				c1.69-1.98,3.02-4.25,3.98-6.84c0.96-2.58,1.44-5.37,1.44-8.34c0-3.04-0.5-5.87-1.48-8.48c-0.99-2.61-2.34-4.91-4.06-6.89
				c-1.72-1.98-3.74-3.51-6.05-4.61c-2.31-1.09-4.83-1.64-7.54-1.64c-2.71,0-5.22,0.55-7.54,1.64c-2.32,1.09-4.35,2.63-6.1,4.61
				c-1.75,1.98-3.1,4.27-4.06,6.89C641.47,66.77,640.99,69.6,640.99,72.64z"
                        />
                        <path
                            className={st0}
                            d="M723.12,38.99c3.9,0,7.37,0.53,10.42,1.6c3.05,1.06,5.6,2.58,7.66,4.56c2.06,1.98,3.63,4.35,4.7,7.11
				c1.07,2.77,1.61,5.88,1.61,9.35c0,2.74-0.38,5.47-1.14,8.21c-0.76,2.74-2,5.23-3.73,7.48c-1.72,2.25-3.99,4.06-6.82,5.43
				c-2.82,1.37-6.32,2.05-10.5,2.05h-8.81v21.34h-12.28V38.99H723.12z M725.24,72c1.86,0,3.42-0.33,4.66-1
				c1.24-0.67,2.21-1.52,2.92-2.55c0.71-1.03,1.21-2.11,1.52-3.24c0.31-1.12,0.47-2.17,0.47-3.15c0-0.73-0.1-1.66-0.3-2.78
				c-0.2-1.12-0.61-2.26-1.23-3.42c-0.62-1.15-1.57-2.13-2.84-2.92c-1.27-0.79-2.98-1.19-5.12-1.19h-8.81V72H725.24z M736.75,80.21
				l15.41,25.9h-14.31l-15.75-25.54L736.75,80.21z"
                        />
                        <path
                            className={st1}
                            d="M789.84,40.17h36.32v8.48h-28.11v20.98h24.64v8.48h-24.64v28h-8.21V40.17z"
                        />
                        <path className={st1} d="M839.72,40.17h8.21v65.94h-8.21V40.17z" />
                        <path
                            className={st1}
                            d="M918.55,108.21L871.21,56.5l2.37,0.64l0.17,48.97h-8.21V38.17h0.42l46.74,51.89l-1.86-0.46l-0.17-49.43h8.21
				v68.03H918.55z"
                        />
                        <path
                            className={st1}
                            d="M936.41,106.11V40.17h16.68c6.1,0,11.26,0.99,15.5,2.96c4.23,1.98,7.66,4.62,10.29,7.93
				c2.62,3.31,4.53,6.95,5.71,10.9c1.19,3.95,1.78,7.93,1.78,11.95c0,4.99-0.8,9.45-2.41,13.41c-1.61,3.95-3.81,7.33-6.6,10.12
				c-2.79,2.8-6.01,4.94-9.65,6.43c-3.64,1.49-7.49,2.23-11.56,2.23H936.41z M944.63,97.63h9.91c3.33,0,6.42-0.55,9.27-1.64
				c2.85-1.09,5.33-2.67,7.45-4.74c2.12-2.07,3.77-4.61,4.95-7.62c1.19-3.01,1.78-6.46,1.78-10.35c0-4.01-0.62-7.55-1.86-10.62
				c-1.24-3.07-2.94-5.64-5.08-7.71c-2.15-2.07-4.57-3.63-7.28-4.7c-2.71-1.06-5.53-1.6-8.47-1.6h-10.67V97.63z"
                        />
                        <path
                            className={st1}
                            d="M1000.17,40.17h39.8v8.48h-31.58v20.06h28.2v8.57h-28.2v20.34h32.77v8.48h-40.98V40.17z"
                        />
                        <path
                            className={st1}
                            d="M1071.64,40.17c3.1,0,5.97,0.44,8.59,1.32c2.62,0.88,4.91,2.19,6.86,3.92s3.47,3.88,4.57,6.43
				c1.1,2.55,1.65,5.47,1.65,8.76c0,2.61-0.35,5.17-1.06,7.66c-0.71,2.49-1.86,4.76-3.47,6.79c-1.61,2.04-3.73,3.68-6.35,4.92
				c-2.62,1.25-5.88,1.87-9.78,1.87h-9.06v24.26h-8.21V40.17H1071.64z M1072.48,73.28c2.43,0,4.45-0.39,6.05-1.19
				c1.61-0.79,2.86-1.81,3.77-3.06c0.9-1.25,1.55-2.57,1.95-3.97c0.39-1.4,0.59-2.77,0.59-4.1c0-1.34-0.21-2.7-0.63-4.1
				c-0.42-1.4-1.1-2.72-2.03-3.97c-0.93-1.25-2.15-2.26-3.64-3.06c-1.5-0.79-3.32-1.19-5.46-1.19h-9.48v24.62H1072.48z
				 M1082.56,78.39l16,27.72h-9.48l-16.34-27.45L1082.56,78.39z"
                        />
                        <path
                            className={st1}
                            d="M1142.85,51.94c-2.09-1.15-4.38-2.16-6.86-3.01c-2.48-0.85-4.91-1.28-7.28-1.28c-3.27,0-5.88,0.82-7.83,2.46
				c-1.95,1.64-2.92,3.83-2.92,6.57c0,2.07,0.63,3.82,1.91,5.24c1.27,1.43,2.91,2.66,4.91,3.69c2,1.03,4.13,2.01,6.39,2.92
				c1.86,0.73,3.73,1.6,5.59,2.6c1.86,1,3.54,2.23,5.04,3.69c1.5,1.46,2.68,3.25,3.56,5.38c0.88,2.13,1.31,4.74,1.31,7.84
				c0,3.53-0.8,6.72-2.41,9.58c-1.61,2.86-3.91,5.11-6.9,6.75c-2.99,1.64-6.58,2.46-10.75,2.46c-2.65,0-5.21-0.32-7.66-0.96
				c-2.46-0.64-4.73-1.5-6.82-2.6c-2.09-1.09-3.95-2.22-5.59-3.37l3.64-6.93c1.35,1.03,2.91,2.02,4.66,2.96
				c1.75,0.94,3.58,1.69,5.5,2.23c1.92,0.55,3.78,0.82,5.59,0.82c1.98,0,3.9-0.36,5.76-1.09c1.86-0.73,3.42-1.88,4.66-3.47
				c1.24-1.58,1.86-3.68,1.86-6.29c0-2.19-0.55-4.04-1.65-5.56c-1.1-1.52-2.54-2.81-4.32-3.88c-1.78-1.06-3.71-2.02-5.8-2.87
				c-1.98-0.73-3.94-1.58-5.89-2.55c-1.95-0.97-3.75-2.14-5.42-3.51c-1.67-1.37-3.02-3.06-4.06-5.06c-1.04-2.01-1.57-4.44-1.57-7.3
				c0-3.47,0.78-6.49,2.33-9.07c1.55-2.58,3.68-4.62,6.39-6.11c2.71-1.49,5.81-2.29,9.31-2.42c3.95,0,7.44,0.52,10.46,1.55
				c3.02,1.03,5.69,2.28,8,3.74L1142.85,51.94z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <rect x="1.68" y="1.43" className={st2} width="18.68" height="31.72" />
                <rect x="26.13" y="1.43" className={st2} width="18.68" height="31.72" />
                <rect x="51.06" y="1.43" className={st2} width="18.68" height="31.72" />
                <rect x="75.5" y="1.43" className={st2} width="18.68" height="31.72" />
                <rect x="1.68" y="38" className={st2} width="18.68" height="31.72" />
                <rect x="26.13" y="38" className={st0} width="18.68" height="31.72" />
                <rect x="51.06" y="38" className={st2} width="18.68" height="31.72" />
                <rect x="75.5" y="38" className={st2} width="18.68" height="31.72" />
                <rect x="1.68" y="74.87" className={st2} width="18.68" height="31.72" />
                <rect x="26.13" y="74.87" className={st2} width="18.68" height="31.72" />
                <rect x="51.06" y="74.87" className={st2} width="18.68" height="31.72" />
                <rect x="75.5" y="74.87" className={st2} width="18.68" height="31.72" />
                <rect x="1.68" y="112.01" className={st2} width="18.68" height="31.72" />
                <rect x="26.13" y="112.01" className={st2} width="18.68" height="31.72" />
                <rect x="51.06" y="112.01" className={st2} width="18.68" height="31.72" />
                <rect x="75.5" y="112.01" className={st2} width="18.68" height="31.72" />
            </g>
        </svg>
    );
};

export default PurpleDoorLogoHorizontal;
